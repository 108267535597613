import { Topbar } from "../components/Topbar"

export const PrivacyPolicy = () => {
    return (
        <div className="w-full min-h-screen background">
            <Topbar />
            <div className="container mx-auto text-white pt-20 pd-20 pb-20">
                <h1 class="text-4xl font-extrabold dark:text-white">Privacy Policy</h1>
                {/* <h2 class="text-2xl font-extrabold dark:text-white pt-5">Privacy Policy</h2>
                <h3 class="text-xl font-extrabold dark:text-white pt-5">Privacy Policy</h3> */}
                <p>Important Note: Our Applications are intended for users who are 10 years of age or older. We do not knowingly collect personal information from children under 13 years of age as required by the Children's Online Privacy Protection Rule ("COPPA").</p>
                <h5 className='font-medium text__20 mb-[1rem]'>Definitions</h5>
                <p className='text__16 mb-2'>Applications: BrickTag</p>
                <h5 className='font-medium text__20 mb-[1rem]'>Information We Collect</h5>
                <p className='text__16 mb-2'>At Monkey Business LLC, we collect the following information as part of using our Applications:</p>

                <ul className='text__16 list-disc pl-4 flex flex-wrap gap-y-2 mb-2'>
                    <li className='w-full'>Meta/Oculus UUID: Used for creating accounts to save user settngs data or other game releated data.</li>
                    <li className='w-full'>Meta/Oculus Username: Used for setting username in game to name.</li>
                </ul>



                <h5 className='font-medium text__20 mb-[1rem]'>User & Parents Rights</h5>
                                    <p className='text__16 mb-2'>Parents have ongoing rights with respect to the personal information collected from their children:</p>
                                    <ul className='text__16 list-disc pl-4 flex flex-wrap gap-y-2 mb-2'>
                                        <li className='w-full'>Review: Parents can review their child's personal information.</li>
                                        <li className='w-full'>Revocation of Consent: Parents can revoke their consent and refuse further collection or use of their child's information.</li>
                                        <li className='w-full'>Deletion: Parents can request the deletion of their child's personal information.</li>
                                    </ul>
                                    <p className='text__16 mb-[1.5rem]'>We take reasonable steps to ensure we are dealing with the child's parents when addressing these requests.</p>



                                    <h5 className='font-medium text__20 mb-[1rem]'>Meta Quest User Age Group APIS </h5>
                                    <p className='text__16 mb-[1.5rem]'>The Get Age Category API by Meta will be called to get the Age Category of the User. Certain Restrictions will be taken for users under the age of 13 to not collect personal data as well as other gameplay changes to ensure privacy and safety. Users under the age of 10 will not be allowed to play the game. We do not use the collect age in our game so we will not use Report Age Category API from Meta.</p>

                                    <h5 className='font-medium text__20 mb-[1rem]'>Child Personal Information Protection</h5>
                                    <p className='text__16 mb-[1.5rem]'>At Monkey Business, we are committed to protecting the confidentiality, security, and integrity of personal information collected from children. We minimize data collection, retain data only as long as necessary, and securely dispose of it when no longer needed.</p>

                                    <h5 className='font-medium text__20 mb-[1rem]'>Information Usage</h5>
                                    <p className='text__16 mb-[1.5rem]'>We collect information from the Meta/Oculus Leaderboard API and Achievements API for the sole purpose of enhancing the in-game experience, including but not limited to in-game leaderboards and displaying completed achievements. We do not use this information for external analytics or marketing purposes.</p>
                                    <p className='text__16 mb-[1.5rem]'>By using our Applications, You agree to the use of information in accordance with this Privacy Policy, including compliance with COPPA.</p>

                                    <h5 className='font-medium text__20 mb-[1rem]'>Data Security</h5>
                                    <p className='text__16 mb-[1.5rem]'>We employ robust security measures, including encryption methods and access controls, to protect the collected data from unauthorized access or disclosure.</p>


                                    <h5 className='font-medium text__20 mb-[1rem]'>Data Retention Period</h5>
                                    <p className='text__16 mb-[1.5rem]'>All collected data, including leaderboard information, is retained only for the duration necessary to fulfill the purposes outlined in this privacy policy. Once the data is no longer needed, it is securely disposed of.</p>


                                    <h5 className='font-medium text__20 mb-[1rem]'>Changes to Privacy Policy</h5>
                                    <p className='text__16 mb-[1.5rem]'>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                                    <p className='text__16 mb-[1.5rem]'>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. Please note the Last Updated By tag at the top of this page to see when the privacy policy has been last updated.</p>

                                    <h5 className='font-medium text__20 mb-[1rem]'>Contact Us / Deletion of User Data</h5>
                                    <p className='text__16 mb-[0.5rem]'>If at any time you would like to remove any stored data we may have regarding your activity, please contact us with the email below. If you have any questions about this Privacy Policy, You can also contact us:</p>
                                    <p className='text__16'>By email: <a href="mailto:support@monkeybusiness.gg" className='text-white !underline'>support@monkeybusiness.gg</a></p>


            </div>
        </div>
    )
}