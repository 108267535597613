import { Topbar } from "../components/Topbar"

export const TermsOfService = () => {
    return (
        <div className="w-full min-h-screen background">
            <Topbar />
            <div className="container mx-auto text-white pt-20 pd-20 pb-20">
                                        <h4 className='font-bold text__28 mb-[1.5rem]'>END-USER LICENSE AGREEMENT</h4>

                                        <h5 className='font-medium text__20 mb-[1rem]'>1. Monkey Business LLC GAME TERMS</h5>
                                        <p className='text__16 mb-2'>This End-User License Agreement ("Agreement") is a legal contract between You (“User”) and Monkey Business LLC, located at 1309 Coffeen Avenue STE 14556 Sheridan, Wyoming 82801 (“Licensor”).</p>
                                        <p className='text__16 mb-[1.5rem]'>1.1. Parties: These terms, collectively referred to as the "Monkey Business LLC Game Terms," regulate your utilization of Monkey Business LLC's games (the "Games") and constitute a contractual agreement between you and Monkey Business LLC, a Wyoming-based company. By engaging with the Games, you explicitly consent to and agree to abide by the Monkey Business LLC Game Terms.</p>
                                        <p className='text__16 mb-[1.5rem]'>This Agreement governs your acquisition, installation, and use of games developed and published by Monkey Business LLC ("Games").</p>
                                        <p className='text__16 mb-[1.5rem]'>By acquiring, installing, and using the Games, you agree to be bound by the terms of this Agreement. If you do not agree to the terms of this Agreement, do not install or use the Games.</p>


                                        <h5 className='font-medium text__20 mb-[1rem]'>1. Definitions</h5>
                                        <ul className='text__16 list-disc pl-4 flex flex-wrap gap-y-2 mb-[1.5rem]'>
                                            <li className='w-full'>"Games": Refers to video games developed and published by Monkey Business LLC.</li>
                                            <li className='w-full'>"User": A person engaging in the use of the Games personally.</li>
                                        </ul>

                                        <h5 className='font-medium text__20 mb-[1rem]'>2. Game Usage</h5>
                                        <p className='text__16 mb-[1.5rem]'>By acquiring, installing, and using the Games, you hereby accept the terms of this Agreement without any restrictions.</p>
                                        <p className='text__16 mb-[1.5rem]'>You must be at least 10 years of age to use the Games. Users between the ages of 10 and 13 may have certain restriction on there gameplay. The Get Age Category API by Meta will be called to get the Age Category of the User. We do not use the collect age in our game so we will not use Report Age Category API from Meta. Users between the ages of 10 and 18 (or between 10 and the age of legal majority in your country of residence) or a person with limited capacity shall obtain approval from their parent or legal guardian who agrees to be bound by these provisions of the EULA. Monkey Business LLC's games are designed for individuals aged 10 and above. Unauthorized and unlicensed use of the games by individuals under the age of 10 is strictly prohibited and violates the Terms of the Games. By using the games, you confirm that you are of legal age in your jurisdiction and agree to abide by the Games' Terms. If you are between the ages of 10 and the legal age of majority, you must use the games under the supervision of a parent or legal guardian, who also agrees to be bound by the Games' Terms. Both you and your parent or guardian should review the Games' Terms to understand your rights and obligations. Any violation of this age restriction may lead to the restriction, suspension, or disabling of your access to the games and/or your account. Additionally, the games may receive ratings from the International Age Rating Coalition, and these ratings may vary by jurisdiction. Parents are advised to check the individual rating of each game before allowing their minor children to play.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>3. License Grant</h5>
                                        <p className='text__16 mb-[1.5rem]'>Subject to the terms and conditions of this Agreement, Licensor grants You a non-exclusive, non-transferable, non-sublicensable, temporary, limited license to acquire, install, and use the Games solely for personal and non-commercial purposes.</p>
                                        <p className='text__16 mb-[1.5rem]'>Your license is subject to the applicable license fee payable according to the terms and conditions set forth on the digital distribution platform through which the Games are acquired by You, or according to a separate contract with the Licensor.</p>


                                        <h5 className='font-medium text__20 mb-[1rem]'>4. Intellectual Property and Ownership</h5>
                                        <p className='text__16 mb-[1.5rem]'>Except for the license granted hereunder, Licensor retains all rights, title, interest, and ownership of the Games. The Games and all associated intellectual property are protected by copyright, trademark, and other laws. The Services provided by Monkey Business LLC encompass copyrighted material, technology, trademarks, service marks, trade secrets, and other proprietary information, collectively referred to as the "Proprietary Material." This material may comprise computer code, text, data, video, images, illustrations, animations, sounds, musical compositions and recordings, audiovisual effects, color schemes, business methods and methods of operation, concepts, ideas, know-how, moral rights, and related documentation. All intellectual property rights associated with the Proprietary Material, including patent, copyright, trademark, and trade secret rights, are either owned or licensed by Monkey Business LLC.</p>
                                        <p className='text__16 mb-[1.5rem]'>You hereby agree not to copy, download, reproduce, republish, upload, post, transmit, perform, display, distribute, or sell the Proprietary Material, or to engage in or encourage others to engage in such acts, without the prior written consent of Monkey Business LLC. Additionally, you may not reverse engineer, disassemble, decompile, or translate any computer software programs constituting the Proprietary Material, or attempt to derive the source code of such programs, except to the extent permitted under applicable law. If such activities are allowed by applicable law, any information discovered in the process must be promptly disclosed to Monkey Business LLC and shall be treated as confidential proprietary information.</p>
                                        <p className='text__16 mb-[1.5rem]'>Furthermore, the Proprietary Material, or any portion thereof, may not be modified or used for any purpose other than as expressly authorized in this Agreement. It is important to note that the Proprietary Material may include materials licensed by Monkey Business LLC from third parties, and the licensors of those materials may enforce their rights in the event of any violation of this Agreement. ALL RIGHTS NOT EXPRESSLY GRANTED TO YOU IN THIS AGREEMENT ARE RESERVED BY Monkey Business LLC AND ITS LICENSORS.</p>


                                        <h5 className='font-medium text__20 mb-[1rem]'>5. Restrictions and Obligations</h5>
                                        <p className='text__16 mb-[1rem]'>You are not permitted to:</p>
                                        <ul className='text__16 list-disc pl-4 flex flex-wrap gap-y-2 mb-[1.5rem]'>
                                            <li className='w-full'>"Distribute, lease, license, sell, rent, resell, copy, reproduce, or otherwise transfer or assign the Games without the express prior written consent of the Licensor.</li>
                                            <li className='w-full'>"Use the Games for any commercial purpose.</li>
        
                                        </ul>

                                        <h5 className='font-medium text__20 mb-[1rem]'>6. Code of Conduct</h5>
                                        <p className='text__16 mb-[1.5rem]'>While using the Games, you agree to comply with all applicable laws and a Code of Conduct outlined in this Agreement. Licensor reserves the right to take disciplinary measures, including Account termination, to protect the community.</p>
                                        <p className='text__16 mb-[1rem]'>Code of Conduct </p>
                                        <p className='text__16 mb-[1.5rem]'>When engaging with the Games, you commit to adhering to all relevant laws, regulations, and rules, including additional guidelines outlined in the "Code of Conduct" that oversee your Game usage. Monkey Business retains the right to implement appropriate disciplinary measures, including account termination and deletion, to safeguard the Community.</p>


                                        <p className='text__16 mb-[1.5rem]'>You are prohibited from:</p>

                                        <ul className='text__16 flex flex-wrap gap-y-2 mb-[1.5rem]'>
                                            <li className='w-full'>a. Impersonating any individual or entity, including using a username falsely implying an association with Monkey Business.</li>
                                            <li className='w-full'>b. Engaging in communication that appears to be endorsed by Monkey Business.</li>
                                            <li className='w-full'>c. Sharing personal identifying information about yourself or other Users via Game communication channels, including using usernames containing such information.</li>
                                            <li className='w-full'>d. Harassing, stalking, or threatening fellow Users within the Game.</li>
                                            <li className='w-full'>e. Removing, modifying, or concealing any copyright or trademark owned by Monkey Business within the Game.</li>
                                            <li className='w-full'>f. Transmitting content that violates or infringes on the rights of others, including trademarks, trade secrets, copyrights, and personal rights.</li>
                                            <li className='w-full'>g. Transmitting offensive content, as determined solely by Monkey Business, including unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, sexually explicit, or racially, ethnically, or otherwise discriminatory language.</li>
                                            <li className='w-full'>h. Selecting an in-game or account username that infringes on the proprietary or non-proprietary rights of third parties, or that Monkey Business deems offensive, defamatory, vulgar, obscene, sexually explicit, racially, ethnically, or otherwise objectionable.</li>
                                            <li className='w-full'>i. Transmitting content containing viruses, corrupted data, trojan horses, bots, keystroke loggers, worms, time bombs, cancelbots, or any other computer programming routines intended to damage, interfere with, intercept, mine, scrape, or expropriate any system, data, or personal information.</li>
                                            <li className='w-full'>j. Using chat inappropriately, whether for personal or commercial purposes, including spamming or disruptive communication between Users.</li>
                                            <li className='w-full'>k. Participating in actions that, in Monkey Business's sole judgment, exploit undocumented aspects of the Game to gain an unfair advantage over other Users.</li>
                                            <li className='w-full'>l. Engaging in actions that, in Monkey Business's sole judgment, mislead or defraud other Users, including but not limited to "scamming" or "social engineering."</li>
                                            <li className='w-full'>m. Attempting to unlawfully access parts of the Game or Game servers that are not available to the public.</li>
                                        </ul>

                                        <h5 className='font-medium text__20 mb-[1rem]'>7. Technical Protections</h5>
                                        <p className='text__16 mb-[1.5rem]'>Tampering with access control features or attempting to disable or omit any security features is strictly forbidden. Any unauthorized use of the Games will terminate the license granted in this Agreement.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>8. Availability</h5>
                                        <p className='text__16 mb-[1.5rem]'>The Games may require an Internet connection for proper functioning and access to online features. Certain features may require a valid and active account with an online service.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>9. Game Modifications</h5>
                                        <p className='text__16 mb-[1.5rem]'>Licensor may provide updates to the Games, and You agree to automatic updating without prior notice. The Games' functionality, performance, or both may evolve over time. We reserve the right to introduce new features, impose limits on, suspend, eliminate, change, or update existing features or any part of the Games, or restrict access to specific portions or the entirety of the Games. Free trials or other limited versions of the Games may be offered to allow you to preview them before making a purchase. These versions may include restricted features, limitations on the permitted time of use, and other constraints.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>10. Limitation of Liability & No Warranty</h5>
                                        <p className='text__16 mb-[1.5rem]'>The Games are provided "as is" and "as available," without any warranties or statements of any kind. Licensor disclaims all warranties and conditions. Licensor shall not be liable for any personal injury, death, or any damages arising from the use of the Games.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>11. Health and Safety Advisory </h5>
                                        <p className='text__16 mb-[1.5rem]'>You are responsible for health and safety while using the Games. Review safety information provided and take necessary precautions. Monkey Business emphasizes the importance of reading and adhering to crucial health and safety warnings and instructions before engaging with our games. To mitigate the risk of personal injury, discomfort, and property damage, it is imperative that all users carefully review the provided warnings and instructions available at the designated link before using our games. Certain games may involve intense physical activity, and we recommend consulting with a medical professional before playing. Additionally, it's crucial to note that a small percentage of individuals (about 1 in 4,000) may experience seizures when exposed to specific flashing lights, patterns, or images, even without a history of epilepsy or seizures. Neglecting to follow this health and safety information may result in damage to your virtual reality system, accessories, connected devices, or other property, and could heighten the risk of personal injury, discomfort, or other potential hazards. By using our games, you affirm that you have thoroughly reviewed these instructions (and will continue to review any updates) and commit to acting in accordance with the provided warnings and instructions.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>12. Termination</h5>
                                        <p className='text__16 mb-[1.5rem]'>Licensor reserves the right to terminate this Agreement and further your right to access the Games if you violate this Agreement. In case of termination, uninstall and destroy all copies of the Games.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>13. INDEMNIFICATION</h5>
                                        <p className='text__16 mb-[1.5rem]'>You agree to indemnify and hold Licensor harmless from all third-party claims arising from your use of the Games.To the maximum extent permitted by law, you agree to defend, indemnify, and hold harmless Monkey Business LLC, its officers, directors, employees, and agents, from and against all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney fees) arising from your use of the Services, your violation of any of the Terms, your violation of any third-party right, including without limitation any copyright, trade secret, privacy, or property right, or any claim that your User-Generated Content caused damage to a third party. This defense and indemnification obligation will survive this Agreement and your use of the Services.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>14. Governing Law</h5>
                                        <p className='text__16 mb-[1.5rem]'>This Agreement shall be governed by and construed in accordance with the laws of the United States of America. Any disputes shall be addressed through arbitration in accordance with AAA.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>15. OUR RIGHT TO AMEND AGREEMENT</h5>
                                        <p className='text__16 mb-[1.5rem]'>Monkey Business reserves the right to amend this Agreement at any time, with or without prior notice, by publishing the revised Agreement on the Site. Your ongoing use of the Services following such amendments will be considered as your acceptance of the modified terms. These modifications will solely affect matters and events occurring after the modification date. You are not permitted to alter this Agreement without prior written authorization from an officer of realities.io. It is your responsibility to regularly check this Agreement to ascertain whether any modifications have been made. IF YOU DISAGREE WITH ANY AMENDMENTS TO THIS AGREEMENT, YOU MUST PROMPTLY UNINSTALL ANY GAMES INSTALLED ON YOUR COMPUTER.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>16. USER SUBMISSIONS, INCLUDING USER-GENERATED CONTENT </h5>
                                        <p className='text__16 mb-[1rem]'>IT IS ACKNOWLEDGED THAT USER IDEAS AND USER-GENERATED CONTENT ARE VOLUNTARILY PROVIDED BY YOU. IF YOU PREFER NOT TO HAVE YOUR USER IDEAS AND USER-GENERATED CONTENT INCORPORATED INTO ANY GAME OR WEBSITE FUNCTIONS, YOU MAY CHOOSE NOT TO SHARE THEM WITH Monkey Business LLC.</p>
                                        <p className='text__16 mb-[1.5rem]'>Any comments, feedback, suggestions, gameplay features, level designs, and other submissions ("User Ideas") disclosed, submitted, or offered to Monkey Business LLC shall become the exclusive property of Monkey Business LLC. Unless otherwise prohibited by law, Monkey Business LLC reserves the right to use, sell, exploit, or create derivative works from these User Ideas. Furthermore, Monkey Business LLC may disclose these User Ideas to third parties or the general public, without providing compensation to you.</p>
                                        <p className='text__16 mb-[1.5rem]'>Monkey Business Games encourages users to create and share content derived from our games, granting a personal, non-exclusive license for non-commercial purposes, including screenshots, gameplay videos, and fan art. Users are authorized to share this content on various social media platforms, with the understanding that proper attribution to Monkey Business Games must be provided. While users retain creative freedom, commercial use of the content is prohibited, and any violations of this agreement may result in modification or removal of the content at Monkey Business Games' discretion. Users are responsible for ensuring that their content complies with this agreement and applicable laws, and Monkey Business Games reserves the right to update these terms. For questions or concerns, users can contact support@MonkeyBusiness.gg. By creating and sharing user-generated content, users acknowledge and agree to these terms, expressing gratitude for their participation in the Monkey Business Games community.</p>


                                        <h5 className='font-medium text__20 mb-[1rem]'>17. GENERAL DISCLAIMER </h5>
                                        <p className='text__16 mb-[1rem]'>(a) THE SERVICES AND ANY ACCOMPANYING DOCUMENTATION AND MATERIAL ARE PROVIDED TO YOU BY Monkey Business LLC "AS IS" WITHOUT ANY WARRANTY OF ANY KIND WHATSOEVER. WITHOUT LIMITING THE GENERALITY OF THE PRECEDING SENTENCE, Monkey Business LLC DOES NOT WARRANT THAT THE SERVICES OR ANY PORTION THEREOF (i) WILL BE UNINTERRUPTED, SECURE, OR ERROR-FREE, OR THAT DEFECTS WILL BE CORRECTED; (ii) ARE COMPATIBLE WITH ANY SOFTWARE, INCLUDING INTERNET BROWSER SOFTWARE; (iii) ARE FREE OF INFECTION BY VIRUSES, WORMS, TROJAN HORSES, OR ANY OTHER CONTAMINATING OR DESTRUCTIVE PROPERTIES; OR (iv) ARE FREE OF DEFAMATORY, DEROGATORY, OR ADULT-ORIENTED MATERIAL, OR MATERIAL THAT SOME INDIVIDUALS MAY DEEM OFFENSIVE OR OBJECTIONABLE. TO THE FULLEST EXTENT ALLOWED BY LAW, Monkey Business LLC DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE.</p>
                                        <p className='text__16 mb-[1.5rem]'>(b) Monkey Business LLC IS NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE SERVICES. UNDER NO CIRCUMSTANCES WILL Monkey Business LLC BE RESPONSIBLE FOR ANY LOSS OR DAMAGE, INCLUDING PERSONAL INJURY OR DEATH, RESULTING FROM YOUR USE OF THE SERVICES, ANY USER-GENERATED CONTENT POSTED ON THE SITE OR TRANSMITTED TO ANOTHER USER, OR ANY TRANSACTIONS BETWEEN OR AMONG YOU AND OTHER USERS, WHETHER ONLINE OR OFFLINE.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>18. LIMITATION OF LIABILITY TO THE FULLEST EXTENT ALLOWED BY LAW</h5>
                                        <p className='text__16 mb-[1.5rem]'>Monkey Business LLC, INCLUDING ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AND AGENTS, SHALL NOT BE LIABLE TO YOU FOR ANY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, COMPENSATORY, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU UNDERSTAND AND AGREE THAT Monkey Business LLC CANNOT AND WILL NOT BE RESPONSIBLE FOR ANY LOSS OF YOUR USER-GENERATED CONTENT OR ANY INTERRUPTIONS OF SERVICE, INCLUDING BUT NOT LIMITED TO ISP DISRUPTIONS, SOFTWARE OR HARDWARE FAILURES, OR ANY OTHER EVENT THAT MAY RESULT IN A LOSS OF DATA OR A DISRUPTION OF SERVICE.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>19. GENERAL PROVISIONS</h5>
                                        <p className='text__16 mb-[1rem]'>This Agreement shall be governed by the laws of the United States, excluding the United Nations Convention on Contracts for the International Sale of Goods (CISG). Disputes arising under or in connection with this Agreement shall be decided by the courts of general jurisdiction. Venue for such disputes is the location of Monkey Business LLC's registered office in the United States.</p>
                                        <p className='text__16 mb-[1.5rem]'>The prevailing party in any such proceeding shall be entitled to reimbursement of court costs, including reasonable attorney fees, in addition to any other remedy awarded. If any provision of this Agreement is held by a court or other tribunal of competent jurisdiction to be unenforceable, such provision will be enforced to the maximum extent permissible, and the remaining portions of this Agreement shall remain in full force and effect. You may not transfer or assign any of your rights or obligations provided in this Agreement without the express prior written approval of Monkey Business LLC; Monkey Business LLC may assign this Agreement without restriction of any kind. No failure on the part of Monkey Business LLC to enforce any provision of this Agreement shall be deemed a waiver or consent. This Agreement constitutes and contains the entire agreement and understanding between you and Monkey Business LLC with respect to the subject matter hereof and supersedes any prior oral or written agreements or understandings.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>20. Severability</h5>
                                        <p className='text__16 mb-[1.5rem]'>In the event that any provision of these Monkey Business Games Terms is deemed unlawful, void, or unenforceable for any reason, said provision will be considered separate from these Games Terms and will not impact the validity and enforceability of the remaining provisions. The failure on our part to enforce any of the Games Terms does not constitute a waiver of our right to enforce such terms in the future.</p>


                                        <h5 className='font-medium text__20 mb-[1rem]'>21. Privacy Policy</h5>
                                        <p className='text__16 mb-[1.5rem]'>You accept and agree to the Licensor’s Privacy Policy as found at www.MonkeyBusiness.gg/priacy-policy.</p>

                                        <h5 className='font-medium text__20 mb-[1rem]'>Complaints</h5>
                                        <p className='text__16 mb-[0.5rem]'>In case of any complaints regarding this Agreement or the Games, please contact:</p>
                                        <p className='text__16 mb-[0.5rem]'>Monkey Business LLC</p>
                                        <p className='text__16 mb-[0.5rem]'>Mailing address:</p>
                                        <p className='text__16 mb-[0.5rem]'>Email: <a href="mailto:support@monkeybusiness.gg" className='text-white !underline'>support@monkeybusiness.gg</a></p>
                                        <p className='text__16'>Monkey Business LLC </p>
                                    </div>
        </div>
    )
}