import { useNavigate } from 'react-router-dom';

export const MENU = [
    {
        text: "home",
        url: "/"
    },
    {
        text: "privacy-policy",
        url: "/privacy-policy"
    },
    {
        text: "terms of service",
        url: "/terms-of-service"
    }
]
export const Topbar = ({ activeMenu }) => {
    const navigate = useNavigate();
    return (
        <div className="w-full h-[74px] flex justify-center items-center bg-black gap-6">
            {MENU.map((item, id) => (
                <div className="capitalize text-white py-4 px-3 cursor-pointer" onClick={() => navigate(item.url)} key={id}>{item.text}</div>
            ))}
        </div>
    )
}