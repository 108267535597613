import { LeftLine } from "../components/LeftLine"
import { RightLine } from "../components/RightLine"
import { Topbar } from "../components/Topbar"

export const Home = () => {
    return (
        <div className="w-full min-h-screen background">
            <Topbar />
            <img className="w-[620px] h-[370px] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" src="/assets/glory-effect.png" />
            <div className="min-h-[calc(100vh-74px)] relative flex justify-center items-center">
                <div className="w-[1000px] h-[300px] flex flex-col items-center relative">
                    <p className="text-[96px] leading-[115px] text-[#77FF60] font-pp-gosha-sans">
                        <span className="font-bold">Monkey </span>
                        <span className="font-normal">Business LLC</span>
                    </p>
                    <p className="font-normal text-[32px] leading-[42px] text-white font-roboto-mono mt-8">Swing into Excitement One Game at a Time</p>
                    {/* <button className="w-[192px] h-[56px] rounded-full px-[16px] bg-[#77FF60] flex justify-center items-center font-bold text-[20px] leading-[24px] font-pp-gosha-sans mt-12">CTA Button</button> */}
                    <LeftLine className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/4" />
                    <RightLine className="absolute bottom-0 right-0 transform translate-x-1/2 translate-y-1/4" />
                </div>
            </div>
        </div>
    )
}