export const LeftLine = ({ className }) => {
    return (
        <svg className={className} width="397" height="80" viewBox="0 0 397 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M397 1.00005L183.67 1.00003C172.225 1.00003 161.652 7.11249 155.94 17.0305L129.484 62.9695C123.773 72.8875 113.199 79 101.754 79L-182 78.9999" stroke="url(#paint0_linear_7_22)" stroke-width="2" />
            <defs>
                <linearGradient id="paint0_linear_7_22" x1="-182" y1="1" x2="397" y2="1.00005" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#47FF4F" stop-opacity="0" />
                    <stop offset="1" stop-color="#47FF4F" />
                </linearGradient>
            </defs>
        </svg>
    )
}