export const RightLine = ({ className }) => {
    return (
        <svg className={className} width="495" height="80" viewBox="0 0 495 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.61612e-06 78.9999L213.33 79C224.775 79 235.348 72.8875 241.06 62.9695L267.516 17.0305C273.227 7.11249 283.801 1.00002 295.246 1.00003L579 1.00005" stroke="url(#paint0_linear_7_21)" stroke-width="2" />
            <defs>
                <linearGradient id="paint0_linear_7_21" x1="579" y1="79" x2="6.90222e-05" y2="78.9999" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#47FF4F" stop-opacity="0" />
                    <stop offset="1" stop-color="#47FF4F" />
                </linearGradient>
            </defs>
        </svg>
    )
}